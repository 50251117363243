const $html = document.documentElement;
const $body = document.body;

const queries = {
  "from-tiny": "(min-width: 500px)",
  "to-tiny": "(max-width: 499px)",
  "from-small": "(min-width: 700px)",
  "to-small": "(max-width: 699px)",
  "from-medium": "(min-width: 1000px)",
  "to-medium": "(max-width: 999px)",
  "from-large": "(min-width: 1200px)",
  "to-large": "(max-width: 1199px)",
  "from-big": "(min-width: 1400px)",
  "to-big": "(max-width: 1399px)",
};

const breakpoints = {
  "from-tiny": window.matchMedia(queries["from-tiny"]),
  "to-tiny": window.matchMedia(queries["to-tiny"]),
  "from-small": window.matchMedia([queries["from-small"]]),
  "to-small": window.matchMedia([queries["to-small"]]),
  "from-medium": window.matchMedia(queries["from-medium"]),
  "to-medium": window.matchMedia(queries["to-medium"]),
  "from-large": window.matchMedia(queries["from-large"]),
  "to-large": window.matchMedia(queries["to-large"]),
  "from-big": window.matchMedia(queries["from-big"]),
  "to-big": window.matchMedia(queries["to-big"]),
};

const production = "production" === process.env.NODE_ENV;

export { $html, $body, breakpoints, queries, production };
