import { module as Module } from "modujs";
import { $html } from "../utils/dom";

const CLASS = {
  OPEN: `has-menu-opened`,
  ACTIVE: `is-active`,
};

export default class extends Module {
  constructor(m) {
    super(m);

    this.isActive = $html.classList.contains(CLASS.OPEN);

    this.events = {
      click: {
        close: "close",
        button: "toggle",
      },
    };
  }

  init() {
    // this.trap = focusTrap.createFocusTrap([this.el, '.c-header'])
    this.closeBind = (e) => {
      if (e.key === "Escape") {
        this.close();
      }
    };

    document.addEventListener("keyup", this.closeBind);
  }

  toggle() {
    if (this.isActive) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    if (this.isActive) {
      return;
    }
    this.isActive = true;

    $html.classList.add(CLASS.OPEN);
    this.$("menu")[0].classList.add(CLASS.ACTIVE);

    // this.call("stop", null, "Scroll");
    // this.trap?.activate()
  }

  close() {
    if (!this.isActive) {
      return;
    }
    this.isActive = false;

    $html.classList.remove(CLASS.OPEN);
    this.$("menu")[0].classList.remove(CLASS.ACTIVE);

    // this.call("start", null, "Scroll");
    // this.trap?.deactivate()
  }

  destroy() {
    super.destroy();
    document.removeEventListener("keyup");
  }
}
