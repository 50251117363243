import { module as Module } from "modujs";
import { gsap, ScrollTrigger } from "../lib/gsap";
import { $html, breakpoints } from "../utils/dom";

export default class extends Module {
  constructor(m) {
    super(m);
  }

  init() {
    let mm = gsap.matchMedia();
    mm.add(breakpoints["from-tiny"], () => {
      this.initAnimation();
    });
  }

  initAnimation() {
    const $el = this.$("wrapper")[0];
    const $itemsWrapper = this.$("items")[0];
    const $items = gsap.utils.toArray(this.$("item"));
    const scrollTop = parseFloat(
      getComputedStyle($html).getPropertyValue("--header-height")
    ); // strips the rem unit
    const start = `top top+=${scrollTop * 16}px`; // caculates the px equivalent

    function getScrollAmount() {
      let itemsWidth = $itemsWrapper.scrollWidth;
      return -(itemsWidth - window.innerWidth);
    }

    const tween = gsap.to($itemsWrapper, {
      x: getScrollAmount,
      duration: 3,
      ease: "none",
    });

    ScrollTrigger.create({
      trigger: ".s-history-scroller",
      start: start,
      end: () => `+=${getScrollAmount() * -1}`,
      pin: true,
      animation: tween,
      scrub: 1,
      invalidateOnRefresh: true,
      markers: false,
    });
  }

  destroy() {
    super.destroy();
  }
}
